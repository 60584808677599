import axios from 'axios'
import { func } from 'prop-types';
import { actions } from 'react-table';
import configData from './config.json'


/*var AUTH_TOKEN = localStorage.getItem("jwtToken");
axios.defaults.baseURL = configData.API_URL //"https://localhost:44368";
axios.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`*/

const getAxiosInstance = () => {
   var AUTH_TOKEN = localStorage.getItem("jwtToken");

   const instance = axios.create({
      baseURL: configData.API_URL
   })
   instance.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;

   return instance;
}

//User Management
export function login(userid, password) {
   //get cookie
   let cookie_ati = getCookie("_ati");

   var data = {
      userId: userid,
      userPassword: password,
      ati: cookie_ati
   }
   return getAxiosInstance().post("/api/account/login", data)
}

export function getInventoryFormData() {
   return getAxiosInstance().get("/api/inventory/getFormInfo");
}

export function isAuthorize() {
   return getAxiosInstance().get("/api/account/IsAuthenticated");
}

export function changePassword(currentPassword, newPassword) {
   return getAxiosInstance().post("/api/account/changepassword", {
      currentPassword: currentPassword,
      newPassword: newPassword
   })
}

export function getUserSetting(id){
   return getAxiosInstance().get(`/api/account/getUserSetting?userId=${id}`)
 }

export function addUser(form) {
   return getAxiosInstance().post("/api/account/Register", form)
}

export function editUser(form) {
   return getAxiosInstance().post("/api/account/Edit", form)
}

export function listUser() {
   return getAxiosInstance().get("/api/account/accountList");
}

export function getMarketplace() {
   return getAxiosInstance().get('/api/account/getmarketplace');
}

export function getMarketplace_AllowCreateOrder() {
   return getAxiosInstance().get('/api/order/getmarketplace');
}

export function getUserMarketplace(platformName) {
   return getAxiosInstance().get(`/api/account/getusermarketplace?platformName=${platformName}`)
}

export function getInventoryStatusCount() {
   return getAxiosInstance().get('/api/inventory/GetCount');
}

export function getOrderFailedCount(){
   return getAxiosInstance().get("/api/order/failed-count")
}

export function getOrdersStatusCount(storeFilter) {
   return getAxiosInstance().get(`/api/order/GetStatusList?storeFilter=${storeFilter}`);
}

export function getOrderList(status, storeFilter) {
   return getAxiosInstance().get(`/api/order/list?status=${status}&storeFilter=${storeFilter}`);
}

export function getOrderListBySKU(skuCode) {
   return getAxiosInstance().get(`/api/order/list_by_sku?skuCode=${skuCode}`);
}

export function getOrderListByOrderNumbers(orderNumbers) {
   return getAxiosInstance().get(`/api/order/list_by_ordernumber?orderNumbers=${orderNumbers}`);
}

export function getAWBByOrderNumber(orderId){
   return getAxiosInstance().get(`/api/order/getawb?orderId=${orderId}`)
}

export function addOrder(data) {
   return getAxiosInstance().post('/api/order/create', data);
}

export function deleteOrder(data) {
   return getAxiosInstance().post('/api/order/delete', data);
}

export function resendOrder(data) {
   return getAxiosInstance().post('/api/order/resend', data);
}

export function updateOrderStatus(data){
   return getAxiosInstance().post(`/api/order/updateOrderStatus`,data);
}

export function cancelOrder(data) {
   return getAxiosInstance().post('/api/order/cancelNewOrder', data)
}

export function getOrderDetail(orderId) {
   return getAxiosInstance().get(`/api/order/detail?orderId=${orderId}`);
}

export function exportOrder(status, startDate, endDate) {
   return getAxiosInstance().get(`/api/order/exportOrder?status=${status}&&startDate=${startDate}&&endDate=${endDate}`, { responseType: 'blob' });
}

export function editOrderItem(orderId, orderItemId, newSKU, orderQuantity) {
   return getAxiosInstance().post(`/api/order/editOrderItem`, { orderId, orderItemId, newSKU, orderQuantity });
}

export function listInventory(status) {
   return getAxiosInstance().get(`/api/inventory/list?status=${status}`);
}

export function listInventoryForModal(){
   return getAxiosInstance().get("/api/inventory/listModal")
}

export function getInventory(id) {
   return getAxiosInstance().get(`/api/inventory/detail/${id}`)
}

export function getInventorySyncLog(inventoryId, offset, pageSize) {
   return getAxiosInstance().get(`/api/inventory/getLog?inventoryId=${inventoryId}&offset=${offset}&size=${pageSize}`);
}

export function setBufferStock(bufferStock) {
   return getAxiosInstance().post('/api/inventory/setBufferStock', { BufferStock: bufferStock })
}

export function uploadInventoryFile(req) {
   return getAxiosInstance().post('/api/inventory/uploadInventoryFile', req, {
      header: {
         'Content-Type': 'multipart/form-data'
      }
   });
}

export function updateMarketplaceQuantity(inventoryId) {
   return getAxiosInstance().post(`/api/inventory/updateMarketplaceQuantity`, { inventoryId })
}

export function updateInventory(data) {
   return getAxiosInstance().post(`/api/inventory/update`, data)
}

export function addInventory(data) {
   return getAxiosInstance().post("/api/inventory/add", data);
}

export function deleteInventory(data) {
   return getAxiosInstance().post("/api/inventory/delete", data);
}

export function undeleteInventory(data) {
   return getAxiosInstance().post("/api/inventory/undelete", data);
}

export function exportInventory() {
   return getAxiosInstance().get("/api/inventory/export", { responseType: 'blob' });
}

export function getCatalogue(inventoryId) {
   return getAxiosInstance().get(`/api/inventory/getcatalogue?inventoryId=${inventoryId}`)
}


export function checkSKU(sku) {
   return getAxiosInstance().post('/api/inventory/checksku', { inventorySKU: sku })
}

export function checkSKUPreOrder(sku) {
   return getAxiosInstance().get(`/api/inventory/checkskupreorder?InventorySKU=${sku}`);
 }
 
 export function setPreOrder(data) {
   return getAxiosInstance().post("/api/inventory/setPreOrder", { data });
 }

export function getMarketplaceList2() {
   return getAxiosInstance().get('/api/catalogue/ListMarketplace2');
}

export function getMarketplaceList() {
   return getAxiosInstance().get('/api/catalogue/ListMarketplace');
}

export function getUserMarketplaceList() {
   return getAxiosInstance().get('/api/catalogue/list-marketplace');
}

export function getMarketplaceInventory(marketplace) {
   return getAxiosInstance().get(`/api/catalogue/ListInventory?userMarketplaceId=${marketplace}`)
}

export function getCatalogueDetail(catalogueId) {
   return getAxiosInstance().get(`/api/catalogue/view?catalogueId=${catalogueId}`)
}

export function searchCatalogue(uid,type,value){
   return getAxiosInstance().get(`/api/catalogue/search?uid=${uid}&type=${type}&value=${value}`)
}

export function uploadOrderFile(formData) {
   return getAxiosInstance().post(`/api/order/uploadorderfile`, formData, {
      headers: {
         'Content-Type': 'multipart/form-data'
      }
   });
}


//Management
export function listUOM() {
   return getAxiosInstance().get('/api/management/listuom');
}

export function addUOM(req) {
   return getAxiosInstance().post('/api/management/adduom', req);
}

export function delUOM(req) {
   return getAxiosInstance().post('/api/management/deluom', req);
}

export function exportSplitInventoryCatalogue() {
   return getAxiosInstance().get("/api/split-inventory/export-catalogue", { responseType: 'blob' });
}

export function getSplitInventoryListByUserMarketplaceId(req){
   return getAxiosInstance().get(`/api/split-inventory/list?userMarketplaceId=${req.userMarketplaceId}&criteria=${req.criteria}`)
}

export function getCatalogueListByUserMarketplaceId(req){
   return getAxiosInstance().get(`/api/split-inventory/list-catalogue?userMarketplaceIds=${req.userMarketplaceIds}&type=${req.type}`)
}

export function getSplitInventoryByCatalogueId(req){
   return getAxiosInstance().get(`/api/split-inventory/get?catalogueId=${req.catalogueId}`)
}

export function getSplitInventoryLog(req){
   return getAxiosInstance().get(`/api/split-inventory/log?catalogueId=${req.catalogueId}`)
}

export function exportSplitInventory(req){
   return getAxiosInstance().post("/api/split-inventory/export",req,{responseType:"blob"})
}

export function uploadSplitInventory(req){
   return getAxiosInstance().post("/api/split-inventory/upload",req,{
      headers: {
         'Content-Type': 'multipart/form-data'
      }
   })
}

export function createSplitInventory(req){
   return getAxiosInstance().post("/api/split-inventory/create",req)
}
export function editSplitInventory(req){
   return getAxiosInstance().post("/api/split-inventory/edit",req)
}
export function deleteSplitInventory(req){
   return getAxiosInstance().post("/api/split-inventory/delete",req)
}
export function syncSplitInventory(req){
   return getAxiosInstance().post("/api/split-inventory/sync",req)
}
export function batchDeleteSplitInventory(req){
   return getAxiosInstance().post("/api/split-inventory/batch-delete",req)
}
export function batchSyncSplitInventory(req){
   return getAxiosInstance().post("/api/split-inventory/batch-sync",req)
}


//Integration API
export function addStore(req) {
   return getAxiosInstance().post("/api/integration/addstore", req);
}

export function editStore(req) {
   return getAxiosInstance().post("/api/integration/editStore", req);
}

export function getStore(id) {
   return getAxiosInstance().get(`/api/integration/getStore?id=${id}`);
}

export function zalora_getShipmentProvider(req) {
   return getAxiosInstance().post("/api/integration/zalora_getshipmentprovider", req);
}

export function zalora_getShipmentProvider2(req) {
   return getAxiosInstance().post("/api/integration/zalora_getshipmentprovider2", req);
}

export function getTokenInfo() {
   return getAxiosInstance().get("/api/integration/get-token-info");
}

export function syncInventory(id, syncAll) {
   let req = {
      user_marketplace_id: id,
      sync_all: syncAll
   }
   return getAxiosInstance().post("/api/integration/inventorysync", req);

}

export function syncInventoryByUserMarketplaceId(id, syncAll) {
   let req = {
      user_marketplace_id: id,
      sync_all: syncAll
   }
   return getAxiosInstance().post("/api/integration/inventory-sync", req);

}

function getCookie(cname) {
   var name = cname + "=";
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(';');
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
         c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
         return c.substring(name.length, c.length);
      }
   }
   return "";
}

//Kit Management
export function createKit(req) {
   return getAxiosInstance().post("/api/catalogue/createkit", req);
}

export function editKit(req) {
   return getAxiosInstance().post("/api/catalogue/editkit", req);
}

export function getKit(catalogueId) {
   const req = {
      catalogueId
   };
   return getAxiosInstance().post("/api/catalogue/getkit", req);
}

export function uploadKitFile(formData) {
   return getAxiosInstance().post(`/api/catalogue/upload-kit-file`, formData, {
      headers: {
         'Content-Type': 'multipart/form-data'
      }
   });
}

export function exportKitFile() {
   return getAxiosInstance().get("/api/catalogue/export-kit-file",{ responseType: 'blob' });
}

export function resubmitKit() {
   return getAxiosInstance().post("/api/catalogue/resubmit");
}

export function syncKit() {
   return getAxiosInstance().post("/api/catalogue/sync-kit");
}

//Dashboard
export function getSales(days) {
   return getAxiosInstance().get(`/api/dashboard/sales?days=${days}`)
}

export function getSalesByMarketplace(days) {
   return getAxiosInstance().get(`/api/dashboard/sales_usermarketplace?days=${days}`)
}

export function getTopSalesCategory(days) {
   return getAxiosInstance().get(`/api/dashboard/categories?days=${days}`)
}

export function getSales3(days){
   return getAxiosInstance().get(`/api/dashboard/sales3?days=${days}`)
}

export function getSalesByDate(startDate,endDate){
   return getAxiosInstance().get(`/api/dashboard/sales_date?sDate=${startDate}&eDate=${endDate}`)
}

export function getSalesByMarketplaceAndDate(startDate,endDate){
   return getAxiosInstance().get(`/api/dashboard/sales_usermarketplace_date?sDate=${startDate}&eDate=${endDate}`)
}

export function getTopSalesCategoryByDate(startDate,endDate){
   return getAxiosInstance().get(`/api/dashboard/categories_date?sDate=${startDate}&eDate=${endDate}`)
}

//Product List/Detail/Count/Log
export function getMarketplacesList() {
   return getAxiosInstance().get('/api/marketplace-product/ListMarketplace');
}
export function getProductList(userMarketplaceId, status) {
   return getAxiosInstance().get(`/api/marketplace-product/ListProduct?UserMarketplaceId=${userMarketplaceId}&status=${status}`);
 }
 export function getProductCount(userMarketplaceId) {
   return getAxiosInstance().get(`/api/marketplace-product/getCount?UserMarketplaceId=${userMarketplaceId}`);
 }
 export function getProductLog(CatalogueBasicInfoId, pageSize, offset) {
   return getAxiosInstance().get(`/api/marketplace-product/getLog?CatalogueBasicInfoId=${CatalogueBasicInfoId}&size=${pageSize}&offset=${offset}`);
 }
 export function getProductDetail(CatalogueBasicInfoId,cancelReq) {
   return getAxiosInstance().get(`/api/marketplace-product/ProductDetail?CatalogueBasicInfoId=${CatalogueBasicInfoId}`,cancelReq);
 }
 
 export function deleteProduct(req) {
   return getAxiosInstance().post("/api/marketplace-product/DeleteProduct", req);
 }
 export function updateProductStatus(catalogueBasicInfoId,status){
   return getAxiosInstance().post(`/api/marketplace-product/ManageStatusProduct?CatalogueBasicInfoId=${catalogueBasicInfoId}&Status=${status}`)
 }
 
 export function deleteVariation(req) {
   return getAxiosInstance().post("/api/marketplace-product/DeleteVariation", req);
 }
 
 export function editProduct(req) {
   return getAxiosInstance().post("/api/marketplace-product/EditProduct", req);
 }
 
 export function resendProduct(req) {
   return getAxiosInstance().post("/api/marketplace-product/resendAddProductMarketplace", req);
 }

 //Product Add
 export function getMarketplaceCategory(userMarketplaceId,cancelReq) {
   return getAxiosInstance().get(`/api/marketplace-product/GetMarketplaceCategory?UserMarketplaceId=${userMarketplaceId}`,cancelReq);
 }
 export function getMarketplaceAttribute(categoryId, userMarketplaceId) {
   return getAxiosInstance().get(`/api/marketplace-product/GetMarketplaceAttribute?categoryId=${categoryId}&UserMarketplaceId=${userMarketplaceId}`);
 }
 export function getMarketplaceBrands(userMarketplaceId,categoryId,cancelReq) {
   return getAxiosInstance().get(`/api/marketplace-product/GetMarketplaceBrands?UserMarketplaceId=${userMarketplaceId}&categoryId=${categoryId}`,cancelReq);
 }
 export function addProductMarketplace(req) {
   return getAxiosInstance().post("/api/marketplace-product/AddProductMarketplace", req);
 }
 export function getInventoryBySku(sku) {
   return getAxiosInstance().get(`/api/marketplace-product/searchInventory?MarketplaceSku=${sku}`);
 }
 export function getMarketplaceLogistics(userMarketplaceId) {
   return getAxiosInstance().get(`/api/marketplace-product/GetLogistics?UserMarketplaceId=${userMarketplaceId}`);
 }

 //Image
 export function deleteImage(req) {
    let AUTH_TOKEN=localStorage.getItem("jwtToken")
   let token = `Bearer ${AUTH_TOKEN}`;
   return getAxiosInstance().delete(`/api/marketplace-product/deleteImage`, {
     headers: { "content-type": "application/json", Authorization: token },
     data: req,
   });
 }
 
 export function uploadImage() {
    let AUTH_TOKEN=localStorage.getItem("jwtToken")
   let token = `Bearer ${AUTH_TOKEN}`;
   return {
     action: "/api/marketplace-product/uploadImage",
     headers: { Authorization: token },
   };
 }

 //Promotion
 export function addPromotion(req) {
   return getAxiosInstance().post("/api/promotion/addPromotion", req);
}

export function getPromotionList(status) {
   return getAxiosInstance().get(`/api/promotion/getPromotionList?status=${status}`);
}

export function getPromotionDetail(id) {
   return getAxiosInstance().get(`/api/promotion/getPromotionDetail?id=${id}`);
}

export function editPromotion(req) {
   return getAxiosInstance().post("/api/promotion/editPromotion", req);
}

export function deletePromotion(id) {
   return getAxiosInstance().get(`/api/promotion/deletePromotion?id=${id}`);
}

export function getPromotionCount() {
   return getAxiosInstance().get(`/api/promotion/getPromotionCount`);
}